<template>
  <div>
    <v-card-title><p class="mb-0 text-h5">Términos y condiciones de uso</p></v-card-title>
    <v-card-text class="black--text pb-0">
      <p class="text-justify">
        El acceso y uso de este sitio Web se rige por los términos y condiciones descritos a continuación, así como por
        la legislación que se aplique en la República de Chile. En consecuencia, todas las visitas y transacciones que
        se realicen en este sitio, como asimismo sus efectos jurídicos, quedarán regidos por estas reglas y sometidas a
        esa legislación.
      </p>
      <p class="text-justify">
        Los términos y condiciones contenidos en este instrumento se aplicarán y se entenderán formar parte de todos los
        actos y contratos que se ejecuten o celebren mediante los sistemas de oferta y comercialización comprendidos en
        este sitio Web entre los usuarios y el Conservador de Bienes Raíces de Coquimbo.
      </p>

      <strong>A continuación se exponen los términos y condiciones:</strong>
    </v-card-text>


    <v-card-text>
      <v-expansion-panels v-model="panelAbierto">
        <v-expansion-panel
            v-for="(data,i) in dataTYC"
            :key="i"
            :id="data.id"
            :class="{'blue lighten-5': panelAbierto === i}"
        >
          <v-expansion-panel-header>
            <template v-slot:default="{ open }">
              <div class=" align-center">
                <p class="font-weight-regular" :class="{'mb-2': open, 'mb-0': !open}">{{ data.titulo }}</p>
                <v-fade-transition>


                  <a v-if="open"
                     key="open"
                     :href="'#'+data.id"
                     class="mt-3"
                  >
                    {{ '#' + data.id }}
                  </a>
                </v-fade-transition>
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="mb-0 text-justify" v-html="data.cuerpo"></p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: "InformacionViewTerminosYCondiciones",
  data: () => ({
    panelAbierto: null,
    dataTYC: [
      {
        id: "registro",
        titulo: "Registro del usuario",
        cuerpo:
            'Será requisito para la solicitud de copias, certificados y alzamientos de prohibiciones/cancelación de hipotecas ofrecidos la aceptación de las presentes condiciones y el registro por parte del usuario con definición de una clave de acceso. Se entenderán conocidos y aceptados estos términos y condiciones por el sólo hecho del registro. El registro de cada usuario se verificará completando y suscribiendo el formulario que al efecto se contiene en el sitio y su posterior envío, el cual se realiza automáticamente mediante un "click" en el elemento respectivo. El Conservador de Bienes Raíces de Coquimbo considerará la información proporcionada por el usuario como fidedigna.',
      },
      {
        id: "clave",
        titulo: "Clave secreta",
        cuerpo:
            "El usuario dispondrá, una vez registrado, de un usuario (Rut) y contraseña que le permitirá el acceso personalizado, confidencial y seguro. El usuario tendrá la posibilidad de cambiar la clave de acceso, para lo cual deberá sujetarse al procedimiento establecido en el sitio. El usuario asume totalmente la responsabilidad por el mantenimiento de la confidencialidad de su clave secreta registrada en este sitio Web. Dicha clave es de uso personal y su entrega a terceros no involucra responsabilidad del Conservador de Bienes Raíces de Coquimbo.",
      },
      {
        id: "usuario",
        titulo: "Derechos del usuario de este sitio",
        cuerpo:
            "El usuario gozará de todos los derechos que le reconoce la legislación vigente en la República de Chile, y además, los que se le otorgan en estos términos y condiciones. El usuario dispondrá en todo momento de los derechos de información, rectificación y cancelación de los datos personales conforme a la Ley Nº 19.628 sobre protección de datos de carácter personal.",
      },
      {
        id: "pagos",
        titulo: "Medios de pago que se podrán utilizar en este sitio",
        cuerpo:
            "Los documentos que se requieran en este sitio, salvo que se señale una forma diferente para casos particulares, sólo pueden ser pagados mediante la utilización de los medios habilitados al momento previo de una compra en la sección Carro de compras. El sitio podrá indicar determinadas condiciones de compra según el medio de pago que utilice el usuario. El sitio podrá también requerir determinado medio de pago según se requiera un documento. Finalmente, el sitio podrá cobrar sumas adicionales de dinero según se utilice un determinado medio de pago (recargos operacionales de los recaudadores, externos al Conservador de Bienes Raíces de Coquimbo). Conforme lo señala el artículo 9 del Decreto con Fuerza de Ley 247, la solicitud no se entenderá perfeccionada en tanto el usuario no haya pagado el valor total de la solicitud.",
      },
      {
        id: "documentos",
        titulo: "Documentos ofrecidos",
        cuerpo:
            "Se podrá también requerir en este sitio Web, la emisión de copias, con y sin vigencia, de inscripciones realizadas en el Registro de Comercio, Aguas y Minas de Coquimbo, como asimismo, certificados de vigencia. Adicionalmente podrá solicitar Alzamientos de Prohibiciones y/o Cancelaciones de Hipotecas.",
      },
      {
        id: "precios",
        titulo: "Precios",
        cuerpo:
            "Los derechos registrales y servicios exhibidos en este sitio Web sólo tienen aplicación para las compras efectuadas en el mismo y no son aplicables a transacciones efectuadas en la oficina del Conservador de Bienes Raíces de Coquimbo. Excepcionalmente, a discreción del Conservador, el precio de una copia puede variar si el número de carillas es igual o superior a siete y se ha cobrado por un número inferior.",
      },
      {
        id: "exactitud",
        titulo: "Exactitud de la información",
        cuerpo:
            "A objeto de solicitar una determinada copia, el usuario deberá siempre proporcionar al Conservador de Bienes Raíces de Coquimbo información respecto a la foja, número y año de la inscripción respecto a la cual se solicita la copia.\n                                                            Por su parte, a objeto de solicitar un determinado certificado, el usuario deberá siempre proporcionar a Conservador de Bienes Raíces de Coquimbo información respecto a la foja, número y año de la inscripción que contenga la vigencia a que se hará referencia en el certificado.\n                                                            La exactitud de la información proporcionada por el usuario respecto a la foja, número y año antes referidos, es de exclusiva responsabilidad del usuario. En consecuencia, no habrá derecho a restitución de lo pagado o reemplazo de lo adquirido, en el evento que la información proporcionada no haya sido la correcta y coincidiera con otra inscripción del Registro de Comercio.",
      },
      {
        id: "entrega",
        titulo: "Entrega de los documentos",
        cuerpo:
            "Las copias y certificados solicitados a través de este sitio se sujetarán a las condiciones de despacho y entrega habilitados en el sitio.\n                                                            Todos los documentos emitidos por el Conservador de Bienes Raíces de Coquimbo y que tengan su origen en cualquier tipo de requerimiento efectuado a través de este sitio Web, lo serán mediante la entrega vía correo electrónico (para copias de planos el retiro es presencial en el Conservador de Bienes Raíces de Coquimbo o envío mediante Chilexpress).",
      },
      {
        id: "boleta-honorarios",
        titulo: "Boleta de honorarios",
        cuerpo:
            "El Conservador de Bienes Raíces de Coquimbo emitirá electrónicamente a nombre del requirente, una boleta de honorarios por los servicios profesionales prestados, la que enviará por correo electrónico (o Chilexpress según corresponda) al concluirse exitosamente el requerimiento.",
      },
      {
        id: "retracto",
        titulo: "Derecho de retracto",
        cuerpo:
            "El usuario no podrá retractarse de las solicitudes de copias y/o certificados efectuadas por medio de este sitio una vez pagadas, ni requerir la devolución o el cambio de la copia o certificado solicitado por otro diverso.",
      },
      {
        id: "cambio",
        titulo: "Derecho a cambio / devolución",
        cuerpo:
            "Sin perjuicio de lo indicado en el número anterior, el usuario podrá requerir el cambio de la copia o certificado solicitado, si el recibido es diverso a aquel comprado. \n En caso de existir una devolución de dinero, el monto involucrado es el correspondiente al precio de los documentos, no incluye el recargo aplicado por el recaudador del medio de pago utilizado.",
      },
      {
        id: "solucion-conflictos",
        titulo: "Solución de conflictos",
        cuerpo:
            "Cualquier dificultad o controversia que se suscite en relación con estos términos y condiciones, se resolverá mediante arbitraje, por un árbitro arbitrador que las partes designarán de común acuerdo, y a falta de acuerdo será nombrado por la justicia ordinaria. La sede del arbitraje será la ciudad de Coquimbo.",
      },
    ],
  }),
  mounted() {
    if (this.$route.hash) {
      this.openTab(this.$route.hash);
    } else {
      this.$vuetify.goTo(0);
    }
  },
  watch: {
    $route(to) {
      this.openTab(to.hash);
    }
  },
  methods: {
    openTab(hash) {
      const _hash = hash.replace("#", "");
      const index = this.dataTYC.findIndex((element) => {
        return element.id === _hash;
      })

      if (index !== -1) {
        this.panelAbierto = index;

        setTimeout(() => {
          this.$vuetify.goTo(hash, {
            easing: "easeOutCubic",
            offset: 150
          });
        }, 500);

      }
    }
  }
};
</script>
